import { Icon } from '@/modules/common/components/Icon'
import { City, Genre, Play, Theatre } from '@/payload-types'
import {
  AccordionControl,
  AccordionItem,
  AccordionPanel,
  Group,
  Stack,
  Text,
  UnstyledButton,
} from '@mantine/core'
import { IconCheck, IconFilterCheck } from '@tabler/icons-react'
import clsx from 'clsx'
import { Options, useQueryState } from 'nuqs'
import React, { useCallback, useMemo } from 'react'
import classes from './VideoFilterSelector.module.css'

const SelectorItem = ({
  data,
  setValue,
  currentValue,
}: {
  data: { value: string; label: string }
  setValue: (slug: string | null, options?: Options) => void
  currentValue: string | null
}) => {
  const active = useMemo(() => {
    return data.value === currentValue
  }, [currentValue, data])

  const handleClick = useCallback(() => {
    return setValue(currentValue === data?.value ? '' : data?.value, {
      history: 'push',
      shallow: false,
    })
  }, [currentValue, data, setValue])

  return (
    <UnstyledButton
      px="sm"
      py="xs"
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
      onClick={handleClick}
    >
      <Group justify="space-between">
        <Text size="sm" fw={500} lh={1} c={active ? 'white' : 'dimmed'}>
          {data.label}
        </Text>
        {currentValue === data.value && <Icon icon={IconCheck} size="sm" />}
      </Group>
    </UnstyledButton>
  )
}

interface VideoFilterSelectorProps {
  label: string
  type: 'city' | 'theatre' | 'play' | 'genre'
  data: City[] | Theatre[] | Play[] | Genre[]
}

/**
 * For mobile filters
 */
export const VideoFilterSelector = ({ label, type, data }: VideoFilterSelectorProps) => {
  const [state, setState] = useQueryState(type, { clearOnDefault: true, defaultValue: '' })

  return (
    <AccordionItem value={type}>
      <AccordionControl>
        <Group gap="xs">
          {state && <Icon icon={IconFilterCheck} size="1rem" strokeWidth={2} />}
          <Text fw={500}>{label}</Text>
        </Group>
      </AccordionControl>
      <AccordionPanel>
        <Stack gap={1}>
          {data.map((item) => (
            <SelectorItem
              key={item.slug}
              data={{ label: item?.name, value: item?.slug ?? '' }}
              currentValue={state}
              setValue={setState}
            />
          ))}
        </Stack>
      </AccordionPanel>
    </AccordionItem>
  )
}
