import { Icon } from '@/modules/common/components/Icon'
import { Button, ButtonProps } from '@mantine/core'
import { IconGiftFilled } from '@tabler/icons-react'
import React, { RefObject } from 'react'

interface GiftButtonBaseProps extends ButtonProps {
  children?: React.ReactNode
  ref?: RefObject<HTMLButtonElement>
  onClick?: () => void
}

export const GiftButtonBase: React.FC<GiftButtonBaseProps> = ({
  children,
  ref,
  size = 'md',
  onClick,
  ...props
}) => {
  return (
    <Button
      ref={ref}
      leftSection={<Icon icon={IconGiftFilled} size="1.2rem" />}
      variant="blurred"
      color="gray"
      size={size}
      onClick={onClick}
      {...props}
    >
      {children || 'Подарить'}
    </Button>
  )
}
