import { City, Genre, Play, Theatre } from '@/payload-types'
import { Box, Group, Popover, PopoverTarget, Select, Text, UnstyledButton } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import React, { useMemo } from 'react'
import classes from './VideoFilterPopover.module.css'
import clsx from 'clsx'
import { IconChevronDown } from '@tabler/icons-react'
import { useQueryState } from 'nuqs'

interface VideoFilterPopoverProps {
  label: string
  type: 'city' | 'theatre' | 'play' | 'genre'
  data: City[] | Theatre[] | Play[] | Genre[]
  fullWidth?: boolean
}

export const VideoFilterPopover = ({ label, type, data, fullWidth }: VideoFilterPopoverProps) => {
  const [opened, { toggle, close }] = useDisclosure(false)
  const [state, setState] = useQueryState(type, { clearOnDefault: true, defaultValue: '' })

  const selectData = useMemo(() => {
    return data.map((item) => ({ label: item?.name, value: item?.slug ?? '' }))
  }, [data])

  const currentSelectionLabel = useMemo(() => {
    return data.find((item) => state === item.slug)?.name || ''
  }, [data, state])

  return (
    <Popover
      opened={opened}
      onClose={close}
      onChange={toggle}
      closeOnEscape={true}
      position="bottom-start"
      trapFocus
      width={fullWidth ? 'calc(100% - 2rem)' : undefined}
      classNames={{
        dropdown: classes.popoverDropdown,
      }}
    >
      <PopoverTarget>
        <Group
          component={UnstyledButton}
          justify={fullWidth ? 'space-between' : 'flex-start'}
          gap="xs"
          wrap="nowrap"
          onClick={toggle}
          className={clsx(
            classes.button,
            {
              [classes.buttonActive]: opened,
              [classes.fullWidth]: fullWidth,
            },
            'mantine-active',
          )}
        >
          <Text className={classes.text} fw={600}>
            {currentSelectionLabel || label}
          </Text>
          <Box className={classes.icon}>
            <Box
              component={IconChevronDown}
              className={clsx(classes.chevron, { [classes.chevronOpened]: opened })}
            />
          </Box>
        </Group>
      </PopoverTarget>
      <Popover.Dropdown p={0}>
        <Select
          size="md"
          dropdownOpened={true}
          variant="unstyled"
          data={selectData}
          placeholder={label}
          value={state}
          onChange={(_value, option) => {
            setState(option?.value || '', { history: 'push', shallow: false })
            close()
          }}
          searchable={true}
          clearable={true}
          classNames={{
            root: classes.selectRoot,
            option: classes.selectOption,
            input: classes.selectInput,
            dropdown: classes.selectDropdown,
          }}
          defaultDropdownOpened={true}
          nothingFoundMessage="Ничего не найдено"
          comboboxProps={{
            withinPortal: false,
            offset: 0,
          }}
          scrollAreaProps={{
            offsetScrollbars: false,
          }}
        />
      </Popover.Dropdown>
    </Popover>
  )
}
