'use client'
import { PriceBadge } from '@/modules/common/components/PriceBadge'
import { Video } from '@/payload-types'
import { Anchor, Badge, Group, Stack, Text, Title } from '@mantine/core'
import { IconCircleFilled, IconMapPin } from '@tabler/icons-react'
import Link from 'next/link'
import React from 'react'

interface Props {
  video: Video
  children?: React.ReactNode
}

export const VideoInfo = ({ video, children }: Props) => {
  const createdAtDate = new Date(video.createdAt)
  const today = new Date()
  const dayDifference = (today.getTime() - createdAtDate.getTime()) / (24 * 60 * 60 * 1000)
  const isNew = dayDifference <= 30 //created within 30 days
  return (
    <Stack gap="lg">
      <Stack gap="xs">
        <Group>
          {video.price?.rub && <PriceBadge rub={video?.price?.rub} />}
          {isNew && (
            <>
              <Badge color="red" radius="md" size="md" hiddenFrom="sm">
                Новое
              </Badge>
              <Badge color="red" radius="md" size="lg" visibleFrom="sm">
                Новое
              </Badge>
            </>
          )}
        </Group>
        <Group gap="xs">
          {video.genres?.map((genre) => {
            return (
              typeof genre === 'object' && (
                <React.Fragment key={genre.id}>
                  <Text>{genre?.name}</Text>
                  <IconCircleFilled size="5" color="gray" />
                </React.Fragment>
              )
            )
          })}
          <Text>{video?.runtime} мин</Text>
          <IconCircleFilled size="5" color="gray" />
          <Text>{video?.rating}</Text>
        </Group>
        <Group gap={4}>
          <IconMapPin size="1rem" strokeWidth={2.42} />
          <Anchor
            component={Link}
            href={`/theatre/${typeof video.theatre === 'object' ? video?.theatre?.slug : '/'}`}
          >
            {typeof video.theatre === 'object' && video?.theatre?.name}
          </Anchor>
        </Group>
      </Stack>

      {video?.plot && (
        <Stack gap={0}>
          <Title order={3}>Сюжет</Title>
          <Text>{video?.plot}</Text>
        </Stack>
      )}
      {Array.isArray(video.directedBy) && video.directedBy.length > 0 && (
        <Stack gap={0}>
          <Title order={3}>Режиссер</Title>
          <Text>
            {video.directedBy
              .map((director) => (typeof director === 'object' ? director?.fullName : undefined))
              .join(', ')}
          </Text>
        </Stack>
      )}
      {Array.isArray(video.musicBy) && video.musicBy.length > 0 && (
        <Stack gap={0}>
          <Title order={3}>Музыка</Title>
          <Text>
            {video.musicBy
              .map((person) => (typeof person === 'object' ? person?.fullName : undefined))
              .join(', ')}
          </Text>
        </Stack>
      )}
      {Array.isArray(video.cast) && video.cast.length > 0 && (
        <Stack gap={0}>
          <Title order={3}>В ролях</Title>
          <Text>
            {video.cast
              .map((actor) => (typeof actor === 'object' ? actor?.fullName : undefined))
              .join(', ')}
          </Text>
        </Stack>
      )}
      <Group mt="xl">{children}</Group>
    </Stack>
  )
}
