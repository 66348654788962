'use client'
import {
  ButtonProps,
  Menu,
  MenuDropdown,
  MenuItem,
  MenuLabel,
  MenuTarget,
  Text,
} from '@mantine/core'
import React, { RefObject } from 'react'
import { WatchButtonBase } from './WatchButtonBase'
import { watchVideo } from '../../actions'
import { useDisclosure } from '@mantine/hooks'
import { useRouter } from 'next/navigation'
import { Video } from '@/payload-types'
import { testPayment } from '@/modules/video/actions'

interface WatchButtonProps extends ButtonProps {
  children?: React.ReactNode
  video: Video
  ref?: RefObject<HTMLButtonElement>
}

export const WatchButton: React.FC<WatchButtonProps> = ({
  children,
  ref,
  size = 'md',
  video,
  ...props
}) => {
  const [opened, { toggle, open, close }] = useDisclosure(false)

  const router = useRouter()

  const handleClick = async () => {
    const { prompt, redirect } = await watchVideo(video.id)

    if (prompt) {
      open()
      return
    }

    if (redirect) {
      router.push(redirect)
    }
  }

  return (
    <>
      <Menu opened={opened} onClose={close} position="bottom-start" width={272}>
        <MenuTarget>
          <WatchButtonBase ref={ref} size={size} onClick={handleClick} {...props} />
        </MenuTarget>
        <MenuDropdown px="sm" py="xs" bg="dark.7">
          <MenuLabel c="dark.0" fw={700} fz="sm" mb="xs">
            Выберите способ оплаты
          </MenuLabel>
          <MenuItem
            px="md"
            py="xs"
            bg="dark.5"
            fw={600}
            rightSection={
              <Text fw={600} size="sm">
                {video?.price?.rub!} ₽
              </Text>
            }
            onClick={() => testPayment(video.id)}
          >
            Тестовая оплата
          </MenuItem>
        </MenuDropdown>
      </Menu>
    </>
  )
}
