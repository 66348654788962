'use client'
import { Icon } from '@/modules/common/components/Icon'
import { IconPlay } from '@/modules/common/icons/IconPlay'
import { Button, ButtonProps } from '@mantine/core'
import React, { RefObject } from 'react'

interface WatchButtonBaseProps extends ButtonProps {
  children?: React.ReactNode
  ref?: RefObject<HTMLButtonElement>
  onClick?: () => void
}

export const WatchButtonBase: React.FC<WatchButtonBaseProps> = ({
  children,
  ref,
  size = 'md',
  onClick,
  ...props
}) => {
  return (
    <Button
      ref={ref}
      leftSection={<Icon icon={IconPlay} size="1rem" />}
      variant="white"
      c="black"
      fw={700}
      size={size}
      onClick={onClick}
      {...props}
    >
      {children || 'Смотреть'}
    </Button>
  )
}
