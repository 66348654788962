'use client'
import { Video } from '@/payload-types'
import {
  AspectRatio,
  Badge,
  Box,
  BoxProps,
  Group,
  Paper,
  Stack,
  Text,
  Title,
  UnstyledButton,
} from '@mantine/core'
import classes from './VideoCard.module.css'
import { useQueryState } from 'nuqs'
import { useCallback } from 'react'
import { useRouter } from 'next/navigation'
import clsx from 'clsx'
import { IconPlay } from '@/modules/common/icons/IconPlay'
import Image from 'next/image'
import { PriceBadge } from '@/modules/common/components/PriceBadge'
import { IconCircleFilled, IconMapPin } from '@tabler/icons-react'

export type VideoCardActions = 'playOnClick' | 'selectOnClick'

interface Props extends BoxProps {
  video: Video
  action?: VideoCardActions
  imageSizes?: string
}

export const VideoCard = ({
  video,
  action,
  className,
  imageSizes = '(min-width: 88em) 20vw, (min-width: 62em) 25vw, (min-width: 36em) 33vw, 50vw',
  ...rest
}: Props) => {
  const createdAtDate = new Date(video.createdAt)
  const today = new Date()
  const dayDifference = (today.getTime() - createdAtDate.getTime()) / (24 * 60 * 60 * 1000)
  const isNew = dayDifference <= 30 //created within 30 days
  const [state, setState] = useQueryState('video', { clearOnDefault: true, defaultValue: '' })
  const router = useRouter()

  const handleClick = useCallback(() => {
    switch (action) {
      case 'playOnClick':
        router.push(`/watch/${video.id}`)
        break
      case 'selectOnClick':
        setState(video.id, { history: 'push', shallow: false })
      default:
        break
    }
  }, [video, action, setState, router])

  return (
    <AspectRatio ratio={3 / 4} w="100%">
      <UnstyledButton
        className={clsx(
          'mantine-active',
          classes.button,
          //TODO: test default selection state with defaultValue
          { [classes.selected]: state && state === video.id },
          className,
        )}
        onClick={handleClick}
        {...rest}
      >
        {action === 'playOnClick' && <IconPlay className={classes.iconPlay} />}
        <Paper pos="relative" h="100%" w="100%">
          {typeof video?.coverImagePortrait === 'object' && video?.coverImagePortrait?.url && (
            <Image
              src={video.coverImagePortrait?.url || ''}
              alt={video.coverImagePortrait?.alt || ''}
              fill={true}
              draggable={false}
              quality={80}
              style={{ objectFit: 'cover' }}
              sizes={imageSizes}
            />
          )}
          <Box className={classes.overlay} />
          <Box className={classes.content}>
            <Stack justify="space-between" h="100%">
              {action === 'selectOnClick' ? (
                <Group justify="space-between">
                  {video.price?.rub && <PriceBadge rub={video?.price?.rub} />}
                  {isNew && (
                    <>
                      <Badge color="red" radius="md" size="md" hiddenFrom="sm">
                        Новое
                      </Badge>
                      <Badge color="red" radius="md" size="lg" visibleFrom="sm">
                        Новое
                      </Badge>
                    </>
                  )}
                </Group>
              ) : (
                <Box />
              )}

              <Stack gap={0}>
                <Title order={2} size="md">
                  {video?.title}
                </Title>
                <Group>
                  {video.genres?.map((genre) => {
                    return typeof genre === 'object' && <Text key={genre.id}>{genre?.name}</Text>
                  })}
                </Group>
                <Box>
                  <Group gap="xs">
                    <Text>{video?.runtime} мин</Text>
                    <IconCircleFilled size="5" color="gray" />
                    <Text>{video?.rating}</Text>
                  </Group>
                  {typeof video?.theatre === 'object' && (
                    <Box className={classes.locationWrapper}>
                      <Group gap={4} className={clsx(classes.location)}>
                        <IconMapPin size="1rem" strokeWidth={2.42} />
                        <Text c="white">{video?.theatre?.name}</Text>
                      </Group>
                    </Box>
                  )}
                </Box>
              </Stack>
            </Stack>
          </Box>
        </Paper>
      </UnstyledButton>
    </AspectRatio>
  )
}
