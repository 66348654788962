'use client'
import { City, Genre, Play, Theatre } from '@/payload-types'
import { Accordion, Box, Drawer, Group, Text, UnstyledButton } from '@mantine/core'
import React from 'react'
import { VideoFilterPopover } from './VideoFilterPopover'
import { VideoFilterSelector } from './VideoFilterSelector'
import clsx from 'clsx'
import classes from './VideoFilters.module.css'
import { IconChevronRight } from '@tabler/icons-react'
import { useDisclosure } from '@mantine/hooks'

interface VideoFiltersProps {
  cities?: City[]
  theatres?: Theatre[]
  plays?: Play[]
  genres?: Genre[]
}

export const VideoFilters = ({ cities, theatres, plays, genres }: VideoFiltersProps) => {
  const [opened, { toggle, open, close }] = useDisclosure(false)
  return (
    <>
      <Group py={{ base: 'sm', md: 'md' }} visibleFrom="xs">
        {cities && <VideoFilterPopover type="city" data={cities} label="Город" />}
        {theatres && <VideoFilterPopover type="theatre" data={theatres} label="Театр" />}
        {plays && <VideoFilterPopover type="play" data={plays} label="Спектакль" />}
        {genres && <VideoFilterPopover type="genre" data={genres} label="Жанр" />}
      </Group>
      <Group
        component={UnstyledButton}
        gap="md"
        justify="space-between"
        wrap="nowrap"
        className={clsx(classes.button, 'mantine-active')}
        hiddenFrom="xs"
        my="sm"
        onClick={open}
      >
        <Text className={classes.text} fw={700}>
          Фильтры
        </Text>
        <Box className={classes.icon}>
          <Box
            component={IconChevronRight}
            className={clsx(classes.chevron, { [classes.chevronOpened]: opened })}
            strokeWidth={3}
          />
        </Box>
      </Group>
      <Drawer
        opened={opened}
        onClose={close}
        position="bottom"
        closeOnClickOutside={true}
        withCloseButton={false}
        zIndex={10}
        withinPortal={true}
        size={'xs'}
        styles={{
          content: {
            borderTopRightRadius: 'var(--mantine-radius-lg)',
            borderTopLeftRadius: 'var(--mantine-radius-lg)',
          },
        }}
      >
        <Accordion>
          {cities && <VideoFilterSelector type="city" data={cities} label="Город" />}
          {theatres && <VideoFilterSelector type="theatre" data={theatres} label="Театр" />}
          {plays && <VideoFilterSelector type="play" data={plays} label="Спектакль" />}
          {genres && <VideoFilterSelector type="genre" data={genres} label="Жанр" />}
        </Accordion>
      </Drawer>
    </>
  )
}
