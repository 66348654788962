import { Badge } from '@mantine/core'
import React from 'react'

interface RUBProps {
  rub: number
  usd?: never
}

interface USDProps {
  usd: number
  rub?: never
}

type Props = RUBProps | USDProps

export const PriceBadge = ({ rub, usd }: Props) => {
  const label = rub ? `${rub} ₽` : usd ? `$${usd}` : ''
  return (
    <>
      <Badge color="white" c="black" radius="md" hiddenFrom="sm" size="md">
        {label}
      </Badge>
      <Badge color="white" c="black" radius="md" visibleFrom="sm" size="lg">
        {label}
      </Badge>
    </>
  )
}
